import React, { useState, useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import { AiOutlineClose } from "react-icons/ai";
import DocView from "../../../core/components/DocView/DocView";

const ContactUsModal = (props) => {
  const {
    modal,
    modals,
    args,
    handleSubmit,
    formData,
    handleChange,
    handlefileChange,
    handleClose,
    type,
    customSub,
    setCustomSub,
  } = props;

  // Regular expression for validating URLs
  const [isLinkValid, setIsLinkValid] = useState(true);

  // Function to validate URL format

  // Function to handle change in link input
  const handleLinkChange = (e) => {
    const { value, name } = e.target;
    // Regular expression for validating URLs
    const urlRegex = /^(ftp|http|https|www)(:\/\/|\.)[^ "]+$/;
    // Check if type is "rezax"
    if (type === "rezax") {
      setIsLinkValid(true); // Hide the error message
    } else {
      // Check if URL is empty or matches the regex pattern
      if (!value || urlRegex.test(value)) {
        setIsLinkValid(true);
        handleChange(e);
      } else {
        setIsLinkValid(false);
      }
    }
  };

  return (
    <Modal isOpen={modal} toggle={modals} {...args} centered>
      <ModalBody className="mb-10 mt-10">
        <div className="d-flex mb-2">
          <h3>Support Form</h3>{" "}
          <AiOutlineClose
            size={20}
            onClick={modals}
            style={{ marginLeft: "auto" }}
            className="text-dark curserPointer"
          />
        </div>
        <p>
          Reach out to us through the Support Form, whether you're experiencing
          technical difficulties, have account-related inquiries, or need
          assistance navigating our platform, we're here to help. Rest assured
          that your query will be handled with care and urgency to ensure a
          swift resolution.
        </p>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="row mt-3">
            <div className="col-sm-6 mt-lg-0 mt-md-0 mt-3 pe-sm-4">
              <div className="form-controls">
                <label htmlFor="" className="form-label">
                  Full Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="full_name"
                  value={formData.full_name}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-sm-6 mt-lg-0 mt-md-0 mt-3 ps-sm-4">
              <div className="form-controls">
                <label htmlFor="" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-12">
              <label className="form-label">Support Type</label>
              <select
                className="form-select"
                aria-label="Default select example"
                value={formData.support_type}
                name="support_type"
                onChange={handleChange}
                required
              >
                <option value="">Select</option>
                <option value="1">General Inquiry</option>
                <option value="2">Technical Issue</option>
                <option value="3">Account Assistance</option>
                <option value="4">Report a Problem</option>
                <option value="5">Feedback or Suggestions</option>
                <option value="6">Billing or Payment Issue</option>
                <option value="7">Privacy Concern</option>
                <option value="8">Content Moderation</option>
                <option value="8">Account Security</option>
                <option value="8">Account Security</option>
              </select>
            </div>
          </div>
          <div className="row mt-3 subject">
            <div className="col-lg-12">
              <label className="form-label">Subject</label>
              {customSub ? (
                <input
                  type="text"
                  className="form-control"
                  value={formData.subject}
                  name="subject"
                  onChange={handleChange}
                  required
                />
              ) : (
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={formData.subject}
                  name="subject"
                  onChange={handleChange}
                  required
                >
                  <option value="">Select</option>
                  <option value="1">Account Access</option>
                  <option value="2">Profile Management</option>
                  <option value="3">Privacy Settings</option>
                  <option value="4">Messaging and Connections</option>
                  <option value="5">Job Search Assistance</option>
                  <option value="6">Advertising and Marketing</option>
                  <option value="7">Premium Membership</option>
                  <option value="8">Reporting Abuse or Violations</option>
                  <option value="9">Technical Issues</option>
                  <option value="9">Feedback and Suggestions</option>
                </select>
              )}
            </div>
            {customSub ? (
              <span onClick={() => setCustomSub(false)}>Select Subject</span>
            ) : (
              <span onClick={() => setCustomSub(true)}>Add Custom Subject</span>
            )}
          </div>
          <div className="row mt-3">
            <div className="col-lg-12">
              <div className="mb-3">
                <label className="form-label">Message</label>
                <textarea
                  className="form-control"
                  rows="3"
                  value={formData.message}
                  name="message"
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-12">
              <div className="">
                <label htmlFor="" className="form-label">
                  Link or Page Where Issue
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.page_link}
                  name="page_link"
                  onChange={(e) => {
                    handleChange(e);
                    handleLinkChange(e);
                  }}
                />
                {!isLinkValid && (
                  <p className="text-danger">Invalid URL format</p>
                )}
              </div>
            </div>
          </div>
          <div className="row mt-30">
            <div className="col-lg-12">
              <label className="form-label">On Which Device</label>
              <div className="radios d-flex align-items-center select-device">
                <div className="radio-row">
                  <input
                    className="me-2"
                    type="radio"
                    id="html"
                    name="device_type"
                    value="web"
                    style={{ height: "18px", width: "18px" }}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="html">Web</label>
                </div>
                <div className="radio-row">
                  <input
                    className="me-2 ms-3"
                    type="radio"
                    id="css"
                    name="device_type"
                    style={{ height: "18px", width: "18px" }}
                    value="ios"
                    onChange={handleChange}
                  />
                  <label htmlFor="css">IOS Mobile App</label>
                </div>
                <div className="radio-row">
                  <input
                    className="me-2 ms-3"
                    type="radio"
                    id="css5"
                    name="device_type"
                    style={{ height: "18px", width: "18px" }}
                    value="android"
                    onChange={handleChange}
                  />
                  <label htmlFor="css5">Android Mobile App</label>
                </div>
                <div className="radio-row">
                  <input
                    className="me-2 ms-3"
                    type="radio"
                    id="css1"
                    name="device_type"
                    style={{ height: "18px", width: "18px" }}
                    value="nobelPage"
                    onChange={handleChange}
                  />
                  <label htmlFor="css1">Nobel Page</label>
                </div>
                <div className="radio-row">
                  <input
                    className="me-2 ms-3"
                    type="radio"
                    id="css2"
                    name="device_type"
                    style={{ height: "18px", width: "18px" }}
                    value="workerPool"
                    onChange={handleChange}
                  />
                  <label htmlFor="css2">Worker Pool</label>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-30">
            <div className="col-lg-12">
              <div className="mb-3">
                {formData.media && (
                  <div className="form-media">
                    {type === ("pdf" || "txt" || "text") ? (
                      <DocView docURL={formData.media} />
                    ) : (
                      <img
                        src={formData.media}
                        alt="attachment"
                        width="100%"
                        height="100%"
                        className="p-2 main-img mb-2"
                      />
                    )}
                    <div className="right-side">
                      <div
                        className="close"
                        onClick={() => handleClose(formData.media)}
                      >
                        X
                      </div>
                    </div>
                  </div>
                )}
                <div className="attachment">
                  <label htmlFor="formFile" className="form-label">
                    Attach File / Screenshot
                  </label>
                  {/* <input
                   type="file"
                   id="formFile"
                  className="form-control"
                  name="media"
                  onChange={(e) => handleChange(e)}
                  style={{opacity:"10", width:"93%", marginBottom:"10px"}}
                /> */}
                  <label
                    for="file-upload"
                    className="custom-file-upload form-control"
                  >
                    {/* <i class="fa fa-cloud-upload"></i>  */}
                    Upload
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    onChange={(e) => handlefileChange(e)}
                  />
                  {/* <div className="relative">
                  <input
                    className="form-control"
                    type="file"
                    id="formFile"
                    // value={formData.media}
                    name="media"
                    onChange={(e) => handlefileChange(e)}
                  />
                </div> */}
                </div>
                <p>* Allowed only files types pdf/txt/text/images</p>
              </div>
            </div>
          </div>
          <div className="text-center mt-2">
            {/* <div className="blue" > */}
            <button type="submit" className="blue noBorder whiteText">
              Submit
            </button>
            {/* </div> */}
          </div>
        </form>
      </ModalBody>
      {/* <ModalFooter>
                <Button color="primary" onClick={modals}>
                Submit
                </Button>
            </ModalFooter> */}
    </Modal>
  );
};

export default ContactUsModal;
