import React, { useEffect, useState } from "react";
import "./SideBox.scss";
import adBarImage from "../../../../assets/img/adbar.png";
import { useSelector } from "react-redux";
import { call } from "../../../../api/apiCall";

const SideBox = () => {
  const [details, setDetails] = useState(null);
  useEffect(() => {
    call(
      "get",
      `api/v1/get-static-pages`,
      { title: "sidebox", status: true },
      null,
      null
    ).then((response) => {
      console.log(response, "static");
      if (response?.data) setDetails(response?.data?.data);
    });
  }, []);

  return details?.status ? (
    <div className="ad-sidebox">
      <div className="advertisement-side">
        <div className="bg-white" style={{ borderRadius: "5px" }}>
          <div className="row p-1 px-4">
            <div className="col-6">
              <h6 className="card-control-text-oneline">{details?.title}</h6>
            </div>
          </div>
          <a href={details?.link || ""} target="_blank">
            <div
              className="ad-image"
              style={{
                backgroundImage: details?.media
                  ? `url(${details?.media})`
                  : adBarImage,
                cursor: "pointer",
              }}
            >
              <div className="bottoms mb-2">
                {/* <h3 className="card-control-text-oneline">{details?.title}</h3> */}
                <h3
                  className="card-control-text-twoline"
                  title={details?.subtitle}
                >
                  {details?.subtitle}
                </h3>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default SideBox;
