import React from "react";
import Header from "../../../core/components/layout/Header/Header";
import HelpCenterSearch from "../components/HelpCenterSearch";
import { useDispatch, useSelector } from "react-redux";
import { getFAQData, postSupportData } from "../../../store/help/helpAction";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import LoginFooter from "../../../core/components/layout/footer/LoginFooter";
import { useState } from "react";
import HelpCenterSearchWebview from "../components/HelpCenterSearchWebview";
import ContactUsModal from "../components/ContactUsModal";
import { ToastContainer, toast } from "react-toastify";

const Listing = (props, args) => {
  const data = useSelector((state) => state.help.faq);
  const { user } = useSelector((state) => state.auth);
  console.log(user?.data, "eded");

  const dispatch = useDispatch();
  const location = useLocation();
  const { tag } = location.state;
  const [mode, setMode] = useState("");
  const [modal, setModal] = useState(false);
  const modals = () => setModal(!modal);
  const initialState = {
    full_name:
      `${user?.data?.first_name || ""} ${user?.data?.last_name || ""}` || "",
    email: user?.data?.email || "",
    support_type: "",
    subject: "",
    custom_subject: "",
    message: "",
    page_link: "",
    device_type: "",
    media: "",
  };
  const [formData, setFormData] = useState(initialState);

  useEffect(() => {
    let params = new URL(document.location).searchParams;
    let param = params.get("mode");
    setMode(param);
    setFormData({
      full_name:
        `${user?.data?.first_name || ""} ${user?.data?.last_name || ""}` || "",
      email: user?.data?.email || "",
      support_type: "",
      subject: "",
      custom_subject: "",
      message: "",
      page_link: "",
      device_type: "",
      media: "",
    });
  }, []);

  useEffect(() => {
    if (tag) {
      dispatch(getFAQData(tag, "tag"));
    }
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(postSupportData(formData)).then((response) => {
      toast.success("Submitted successfully!", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      setFormData(initialState);
      modals();
    });
  };

  return (
    <>
      <div className={mode === "mobile" ? "helpWebView" : ""}>
        {mode === "mobile" ? <></> : <Header />}
        <div className="help d-flex flex-column" style={{ minHeight: "100vh" }}>
          {mode === "mobile" ? (
            <HelpCenterSearchWebview />
          ) : (
            <HelpCenterSearch />
          )}
          <div
            className="container"
            style={{ marginTop: "80px", paddingBottom: "40px" }}
          >
            {data?.length > 0 ? (
              data.map((item) => (
                <div className="faq-list">
                  <Link
                    to={
                      mode === "mobile"
                        ? `/help/question/${item.id}?mode=mobile`
                        : `/help/question/${item.id}`
                    }
                  >
                    <p className="faq-question">{item?.question}</p>
                  </Link>
                  <p className="faq-tag">{item?.tag}</p>
                  <Link
                    to={
                      mode === "mobile"
                        ? `/help/question/${item.id}?mode=mobile`
                        : `/help/question/${item.id}`
                    }
                  >
                    <p className="faq-answer">{item.answer}</p>
                  </Link>
                </div>
              ))
            ) : (
              <div className="text-align-center d-flex justify-content-center">
                No Questions
              </div>
            )}
          </div>
          <div className="container" style={{ marginTop: "auto" }}>
            <div className="row">
              <div className="col-lg-12">
                <div className="bottom my-2">
                  <ul>
                    <li onClick={modals}>Contact us</li>
                    <li>
                      <Link
                        to={mode === "mobile" ? "/help?mode=mobile" : "/help"}
                      >
                        FAQ
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ContactUsModal
          modal={modal}
          modals={modals}
          args={args}
          handleSubmit={handleSubmit}
          formData={formData}
          handleChange={handleChange}
        />
        <ToastContainer />
      </div>
      {mode === "mobile" ? <></> : <LoginFooter />}
    </>
  );
};

export default Listing;
