import React, { useEffect, useRef, useState } from "react";
import placeholderUser from "../../assets/img/placeholder_user.jpg";
import pagePlaceholder from "../../assets/img/page-placeholder.svg";
import defaultGroupIcon from "../../assets/img/default_group_icon.svg";
import npLogo from "../../assets/img/logo.svg";
import "./Notification.scss";
import { Link } from "react-router-dom";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  deleteNotification,
  updateNotification,
} from "../../store/notification/notificationAction";
import { toast } from "react-toastify";
import {
  getUserSetting,
  updateSettingDetails,
} from "../../store/setting/SettingAction";

const ShowNotification = (props) => {
  const { index, notification, handleItemClick } = props;
  const dropRef = useRef(null);
  const [dropDown, setDropDown] = useState(null);
  const [profileImg, setProfileImg] = useState(placeholderUser);
  const [notificationMessage, setNotificationMessage] = useState();
  const [masterId, setMasterId] = useState();
  const dispatch = useDispatch();

  let token = localStorage.getItem("accessToken");

  const getNotiTime = (date) => {
    const now = moment();
    const minutes = now.diff(date, "minutes");
    const hours = now.diff(date, "hours");
    const days = now.diff(date, "days");
    const months = now.diff(date, "months");
    const years = now.diff(date, "years");

    let result;

    if (minutes < 60) {
      result = `${minutes}m`;
    } else if (hours < 24) {
      result = `${hours}h`;
    } else if (days < 30) {
      result = `${days}d`;
    } else if (months < 12) {
      result = `${months}M`;
    } else {
      result = `${years}Y`;
    }

    return result;
  };

  const handleTrunOff = (id) => {
    let myObj = {
      setting_master_id: masterId,
      value: "0",
    };
    let updateData = {
      setting: [myObj],
    };
    dispatch(updateSettingDetails(updateData, token)).then((res) => {
      dispatch(getUserSetting(0, token));
      if (res.success) {
        toast.success(
          "You will no longer receive notifications like these. You can re-enable them from the settings page.",
          { position: toast.POSITION.BOTTOM_LEFT, toastId: "comm" }
        );
        dispatch(deleteNotification(id));
      }
    });
    setDropDown(-1);
  };

  const handleNotificationUpdate = (id, type, status) => {
    let data = {};
    if (type === "pinned") {
      data = {
        pinnedStatus: status,
      };
    } else if (type === "read") {
      data = {
        readStatus: status,
      };
    }
    setDropDown(-1);
    dispatch(updateNotification(id, data, type)).then((res) => {
      toast.success(res.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };

  const handleNotificationDelete = (id) => {
    setDropDown(-1);
    dispatch(deleteNotification(id)).then((res) => {
      toast.success(res.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropRef.current && !dropRef.current.contains(event.target)) {
        setDropDown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (notification?.type === "connection-request") {
      setProfileImg(
        notification?.User?.profile_img
          ? notification?.User?.profile_img
          : placeholderUser
      );
      setNotificationMessage(
        <p>
          {`${notification?.content} from `}
          {notification?.User ? (
            <>
              <strong>
                {notification?.User.first_name.length > 15
                  ? notification?.User.first_name.slice(0, 15) + "..."
                  : notification?.User.first_name}
              </strong>{" "}
              <strong>
                {notification?.User.last_name.length > 15
                  ? notification?.User.last_name.slice(0, 15) + "..."
                  : notification?.User.last_name}
              </strong>
            </>
          ) : (
            ""
          )}
        </p>
      );
    } else if (notification?.type === "user") {
      setMasterId(124);
      setProfileImg(notification?.User?.profile_img);
      setNotificationMessage(
        <p>
          You have a np-mail by
          {notification.User ? (
            <>
              {" "}
              <strong>
                {notification?.User.first_name.length > 15
                  ? notification?.User.first_name.slice(0, 15) + "..."
                  : notification?.User.first_name}
              </strong>{" "}
              <strong>
                {notification?.User.last_name.length > 15
                  ? notification?.User.last_name.slice(0, 15) + "..."
                  : notification?.User.last_name}
              </strong>
            </>
          ) : (
            ""
          )}
        </p>
      );
    } else if (notification?.type === "page-follow") {
      setProfileImg(
        notification?.Page?.icon ? notification?.Page?.icon : pagePlaceholder
      );
      setNotificationMessage(
        <p>
          {notification?.Requested_User ? (
            <>
              <strong>
                {notification?.Requested_User?.first_name.length > 15
                  ? notification?.Requested_User?.first_name.slice(0, 15) +
                    "..."
                  : notification?.Requested_User?.first_name}
              </strong>{" "}
              <strong>
                {notification?.Requested_User?.last_name.length > 15
                  ? notification?.Requested_User?.last_name.slice(0, 15) + "..."
                  : notification?.Requested_User?.last_name}
              </strong>
            </>
          ) : (
            ""
          )}{" "}
          has followed the page{" "}
          {notification?.Page ? (
            <strong>{notification?.Page?.name}</strong>
          ) : (
            <></>
          )}
        </p>
      );
    } else if (notification?.type === "accept_friend-request") {
      setMasterId(97);
      setProfileImg(
        notification?.Requested_User?.profile_img
          ? notification?.Requested_User?.profile_img
          : placeholderUser
      );
      setNotificationMessage(
        <p>
          {notification?.Requested_User ? (
            <>
              <strong>
                {notification?.Requested_User?.first_name?.length > 15
                  ? notification?.Requested_User?.first_name.slice(0, 15) +
                    "..."
                  : notification?.Requested_User?.first_name}
              </strong>{" "}
              <strong>
                {notification?.Requested_User?.last_name?.length > 15
                  ? notification?.Requested_User?.last_name.slice(0, 15) + "..."
                  : notification?.Requested_User?.last_name}
              </strong>{" "}
              has accepted your friend request
            </>
          ) : (
            `Your friend request got accepted`
          )}
        </p>
      );
    } else if (notification?.type === "event-attend") {
      setProfileImg(
        notification?.Requested_User?.profile_img
          ? notification?.Requested_User?.profile_img
          : placeholderUser
      );
      setNotificationMessage(
        <p>
          {notification?.Requested_User ? (
            <>
              <strong>
                {notification?.Requested_User?.first_name?.length > 15
                  ? notification?.Requested_User?.first_name.slice(0, 15) +
                    "..."
                  : notification?.Requested_User?.first_name}
              </strong>{" "}
              <strong>
                {notification?.Requested_User?.last_name?.length > 15
                  ? notification?.Requested_User?.last_name.slice(0, 15) + "..."
                  : notification?.Requested_User?.last_name}
              </strong>{" "}
              has requested to attend event{" "}
              <strong> {notification?.Event?.title}</strong>
            </>
          ) : (
            notification?.content
          )}
        </p>
      );
    } else if (notification?.type === "event-speaker") {
      setProfileImg(
        notification?.Event?.media
          ? notification?.Event?.media
          : placeholderUser
      );
      setNotificationMessage(
        <p>
          {notification?.content} <strong> {notification?.Event?.title}</strong>
        </p>
      );
      setMasterId(101);
    } else if (notification?.type === "event-update") {
      setProfileImg(
        notification?.Event?.media
          ? notification?.Event?.media
          : placeholderUser
      );
      setNotificationMessage(
        <p>
          <strong> {notification?.Event?.title}</strong> {notification?.content}
        </p>
      );
      setMasterId(101);
    } else if (notification?.type === "hashtag-follow") {
      setProfileImg(
        notification?.Requested_User?.profile_img
          ? notification?.Requested_User?.profile_img
          : placeholderUser
      );
      setNotificationMessage(
        <p>
          {notification?.Requested_User ? (
            <>
              <strong>
                {notification?.Requested_User?.first_name?.length > 15
                  ? notification?.Requested_User?.first_name.slice(0, 15) +
                    "..."
                  : notification?.Requested_User?.first_name}
              </strong>{" "}
              <strong>
                {notification?.Requested_User?.last_name?.length > 15
                  ? notification?.Requested_User?.last_name.slice(0, 15) + "..."
                  : notification?.Requested_User?.last_name}
              </strong>{" "}
              has followed the hashtag{" "}
              <strong>
                {notification?.Hashtag?.name > 15
                  ? notification?.Hashtag?.name.slice(0, 15) + "..."
                  : notification?.Hashtag?.name}
              </strong>
            </>
          ) : (
            ` User has followed the hashtag`
          )}
        </p>
      );
    } else if (notification?.type === "job-close") {
      setProfileImg(
        notification?.Job?.icon ? notification?.Job?.icon : pagePlaceholder
      );
      setNotificationMessage(
        <p>
          {notification?.Job ? (
            <>
              <strong>{notification?.Job?.title}</strong> job has been closed
            </>
          ) : (
            ` This job has been closed`
          )}
        </p>
      );
      setMasterId(70);
    } else if (notification?.type === "job-shortlist") {
      setProfileImg(
        notification?.Job?.icon ? notification?.Job?.icon : pagePlaceholder
      );
      setNotificationMessage(notification?.content);
      setMasterId(81);
    } else if (notification?.type === "job-desc-change") {
      setProfileImg(
        notification?.Job?.icon ? notification?.Job?.icon : pagePlaceholder
      );
      setNotificationMessage(notification?.content);
      setMasterId(70);
    } else if (notification?.type === "connection_anniversary") {
      setProfileImg(
        notification?.User?.profile_img
          ? notification?.User?.profile_img
          : placeholderUser
      );
      setNotificationMessage(notification?.content);
      setMasterId(96);
    } else if (notification?.type === "smart-interview") {
      setProfileImg(
        notification?.Job?.icon ? notification?.Job?.icon : pagePlaceholder
      );
      setNotificationMessage(
        <>
          <p>{notification?.content}</p>{" "}
          <strong>{notification?.Job?.title}</strong>
        </>
      );
    } else if (notification?.type === "newsletter-subscribe") {
      setProfileImg(
        notification?.Requested_User?.profile_img
          ? notification?.Requested_User?.profile_img
          : placeholderUser
      );
      setNotificationMessage(
        <p>
          {notification?.Requested_User ? (
            <>
              <strong>
                {notification?.Requested_User?.first_name?.length > 15
                  ? notification?.Requested_User?.first_name.slice(0, 15) +
                    "..."
                  : notification?.Requested_User?.first_name}
              </strong>{" "}
              <strong>
                {notification?.Requested_User?.last_name?.length > 15
                  ? notification?.Requested_User?.last_name.slice(0, 15) + "..."
                  : notification?.Requested_User?.last_name}
              </strong>{" "}
              has subscribed the newsletter{" "}
              <strong>
                {notification?.Newsletter?.title.length > 20
                  ? notification?.Newsletter?.title.slice(0, 20) + "..."
                  : notification?.Newsletter?.title}
              </strong>{" "}
            </>
          ) : (
            notification?.content
          )}{" "}
          '
        </p>
      );
    } else if (
      notification?.type === "page-admin" ||
      notification?.type === "page-transfer" ||
      notification?.type === "page-inivitation"
    ) {
      setProfileImg(
        notification?.Page?.icon ? notification?.Page?.icon : pagePlaceholder
      );
      setNotificationMessage(
        <p>
          <>
            {notification?.content} <strong>{notification?.Page?.name}</strong>{" "}
          </>
        </p>
      );
    } else if (notification?.type === "group-join") {
      setProfileImg(
        notification?.Group?.icon ? notification?.Group?.icon : defaultGroupIcon
      );
      setNotificationMessage(
        <p>
          <>
            <strong>
              {notification?.Requested_User?.first_name?.length > 15
                ? notification?.Requested_User?.first_name.slice(0, 15) + "..."
                : notification?.Requested_User?.first_name}
            </strong>{" "}
            <strong>
              {notification?.Requested_User?.last_name?.length > 15
                ? notification?.Requested_User?.last_name.slice(0, 15) + "..."
                : notification?.Requested_User?.last_name}
            </strong>{" "}
            {notification?.content} <strong>{notification?.Group?.name}</strong>{" "}
          </>
        </p>
      );
    } else if (notification?.type === "group-member") {
      setProfileImg(
        notification?.Group?.icon ? notification?.Group?.icon : defaultGroupIcon
      );
      setNotificationMessage(
        <p>
          <>
            {notification?.content} <strong>{notification?.Group?.name}</strong>{" "}
          </>
        </p>
      );
      setMasterId(102);
    } else if (notification?.type === "group-request") {
      setProfileImg(
        notification?.Requested_User?.profile_img
          ? notification?.Requested_User?.profile_img
          : placeholderUser
      );
      setNotificationMessage(
        <p>
          <>
            {notification?.content} <strong>{notification?.Group?.name}</strong>{" "}
            by{" "}
            <strong>
              {notification?.Requested_User?.first_name?.length > 15
                ? notification?.Requested_User?.first_name.slice(0, 15) + "..."
                : notification?.Requested_User?.first_name}
            </strong>{" "}
            <strong>
              {notification?.Requested_User?.last_name?.length > 15
                ? notification?.Requested_User?.last_name.slice(0, 15) + "..."
                : notification?.Requested_User?.last_name}
            </strong>{" "}
          </>
        </p>
      );
      setMasterId(102);
    } else if (notification?.type === "new-follwer") {
      setProfileImg(
        notification?.Requested_User?.profile_img
          ? notification?.Requested_User?.profile_img
          : placeholderUser
      );
      setNotificationMessage(
        <p>
          <>
            <strong>
              {notification?.Requested_User?.first_name?.length > 15
                ? notification?.Requested_User?.first_name.slice(0, 15) + "..."
                : notification?.Requested_User?.first_name}
            </strong>{" "}
            <strong>
              {notification?.Requested_User?.last_name?.length > 15
                ? notification?.Requested_User?.last_name.slice(0, 15) + "..."
                : notification?.Requested_User?.last_name}
            </strong>{" "}
            has follwed you
          </>
        </p>
      );
      setMasterId(111);
    } else if (notification?.type === "view-profile") {
      setProfileImg(
        notification?.Requested_User?.profile_img
          ? notification?.Requested_User?.profile_img
          : placeholderUser
      );
      setNotificationMessage(
        <p>
          <>
            <strong>
              {notification?.Requested_User?.first_name?.length > 15
                ? notification?.Requested_User?.first_name.slice(0, 15) + "..."
                : notification?.Requested_User?.first_name}
            </strong>{" "}
            <strong>
              {notification?.Requested_User?.last_name?.length > 15
                ? notification?.Requested_User?.last_name.slice(0, 15) + "..."
                : notification?.Requested_User?.last_name}
            </strong>{" "}
            {notification?.content}
          </>
        </p>
      );
      setMasterId(112);
    } else if (notification?.type === "birthday") {
      setProfileImg(
        notification?.Requested_User?.profile_img
          ? notification?.Requested_User?.profile_img
          : placeholderUser
      );
      setNotificationMessage(
        <p>
          <>
            Today is{" "}
            <strong>
              {notification?.Requested_User?.first_name?.length > 15
                ? notification?.Requested_User?.first_name.slice(0, 15) + "..."
                : notification?.Requested_User?.first_name}
            </strong>{" "}
            <strong>
              {notification?.Requested_User?.last_name?.length > 15
                ? notification?.Requested_User?.last_name.slice(0, 15) + "..."
                : notification?.Requested_User?.last_name}
            </strong>
            's birthday! 🎉
          </>
        </p>
      );
      setMasterId(93);
    } else if (notification?.type === "new-connection") {
      setProfileImg(
        notification?.User?.profile_img
          ? notification?.User?.profile_img
          : placeholderUser
      );
      setNotificationMessage(
        <p>
          <>
            Your connection{" "}
            <strong>
              {notification?.User?.first_name?.length > 15
                ? notification?.User?.first_name.slice(0, 15) + "..."
                : notification?.User?.first_name}
            </strong>{" "}
            <strong>
              {notification?.User?.last_name?.length > 15
                ? notification?.User?.last_name.slice(0, 15) + "..."
                : notification?.User?.last_name}
            </strong>{" "}
            {" has a new connection "}
            <strong>
              {notification?.Requested_User?.first_name?.length > 15
                ? notification?.Requested_User?.first_name.slice(0, 15) + "..."
                : notification?.Requested_User?.first_name}
            </strong>{" "}
            <strong>
              {notification?.Requested_User?.last_name?.length > 15
                ? notification?.Requested_User?.last_name.slice(0, 15) + "..."
                : notification?.Requested_User?.last_name}
            </strong>
          </>
        </p>
      );
      setMasterId(108);
    } else if (notification?.type === "group-post") {
      setProfileImg(
        notification?.Group?.icon ? notification?.Group?.icon : defaultGroupIcon
      );
      setMasterId(104);
      setNotificationMessage(
        <>
          <p>
            <strong>
              {notification?.Requested_User?.first_name?.length > 15
                ? notification?.Requested_User?.first_name.slice(0, 15) + "..."
                : notification?.Requested_User?.first_name}
            </strong>{" "}
            <strong>
              {notification?.Requested_User?.last_name?.length > 15
                ? notification?.Requested_User?.last_name.slice(0, 15) + "..."
                : notification?.Requested_User?.last_name}
            </strong>{" "}
            has posted a new post in{" "}
            <strong>{notification?.Group?.name}</strong> group
          </p>{" "}
        </>
      );
    } else if (notification?.type === "group-post_notify_admin") {
      setProfileImg(
        notification?.Group?.icon ? notification?.Group?.icon : defaultGroupIcon
      );
      setMasterId(105);
      setNotificationMessage(
        <>
          <p>
            <strong>
              {notification?.Requested_User?.first_name?.length > 15
                ? notification?.Requested_User?.first_name.slice(0, 15) + "..."
                : notification?.Requested_User?.first_name}
            </strong>{" "}
            <strong>
              {notification?.Requested_User?.last_name?.length > 15
                ? notification?.Requested_User?.last_name.slice(0, 15) + "..."
                : notification?.Requested_User?.last_name}
            </strong>{" "}
            has posted a new post in{" "}
            <strong>{notification?.Group?.name}</strong> group
          </p>{" "}
        </>
      );
    } else if (notification?.type === "view-job") {
      setProfileImg(
        notification?.Job?.icon ? notification?.Job?.icon : pagePlaceholder
      );
      setNotificationMessage(
        <>
          <p>
            <strong>
              {notification?.Requested_User?.first_name?.length > 15
                ? notification?.Requested_User?.first_name.slice(0, 15) + "..."
                : notification?.Requested_User?.first_name}
            </strong>{" "}
            <strong>
              {notification?.Requested_User?.last_name?.length > 15
                ? notification?.Requested_User?.last_name.slice(0, 15) + "..."
                : notification?.Requested_User?.last_name}
            </strong>{" "}
            {notification?.content}
          </p>{" "}
        </>
      );
      setMasterId(80);
    } else if (notification?.type === "job-matched") {
      setProfileImg(
        notification?.Group?.icon ? notification?.Group?.icon : defaultGroupIcon
      );
      setMasterId(105);
      setNotificationMessage(<p>{notification?.content}</p>);
    } else if (notification?.type === "like-feed") {
      setProfileImg(
        notification?.Group?.icon ? notification?.Group?.icon : defaultGroupIcon
      );
      setMasterId(66);
      setNotificationMessage(<p>{notification?.content}</p>);
    } else if (notification?.type === "add-comment") {
      setProfileImg(
        notification?.Group?.icon ? notification?.Group?.icon : defaultGroupIcon
      );
      setMasterId(66);
      setNotificationMessage(<p>{notification?.content}</p>);
    } else if (notification?.type === "poll-expire") {
      setProfileImg(
        notification?.Group?.icon ? notification?.Group?.icon : defaultGroupIcon
      );
      setMasterId(73);
      setNotificationMessage(<p>{notification?.content}</p>);
    } else if (notification?.type === "post-promote") {
      setProfileImg(
        notification?.Group?.icon ? notification?.Group?.icon : defaultGroupIcon
      );
      setMasterId(74);
      setNotificationMessage(<p>{notification?.content}</p>);
    } else if (notification?.type === "user-job-alert") {
      setProfileImg(
        notification?.Group?.icon ? notification?.Group?.icon : defaultGroupIcon
      );
      setMasterId(88);
      setNotificationMessage(<p>{notification?.content}</p>);
    } else if (notification?.type === "user-job-recommendation") {
      setProfileImg(
        notification?.Group?.icon ? notification?.Group?.icon : defaultGroupIcon
      );
      setMasterId(83);
      setNotificationMessage(<p>{notification?.content}</p>);
    } else if (notification?.type === "job-preference-update") {
      setProfileImg(
        notification?.Group?.icon ? notification?.Group?.icon : defaultGroupIcon
      );
      setMasterId(85);
      setNotificationMessage(<p>{notification?.content}</p>);
    } else if (notification?.type === "salary-insights") {
      setProfileImg(
        notification?.Group?.icon ? notification?.Group?.icon : defaultGroupIcon
      );
      setMasterId(87);
      setNotificationMessage(<p>{notification?.content}</p>);
    } else if (notification?.type === "job-reminder") {
      setProfileImg(
        notification?.Group?.icon ? notification?.Group?.icon : defaultGroupIcon
      );
      setMasterId(79);
      setNotificationMessage(<p>{notification?.content}</p>);
    } else if (notification?.type === "same-job-opportunity-you-hiring") {
      setProfileImg(
        notification?.Group?.icon ? notification?.Group?.icon : defaultGroupIcon
      );
      setMasterId(84);
      setNotificationMessage(<p>{notification?.content}</p>);
    } else if (notification?.type === "coworker-activity") {
      setProfileImg(
        notification?.Group?.icon ? notification?.Group?.icon : defaultGroupIcon
      );
      setMasterId(92);
      setNotificationMessage(<p>{notification?.content}</p>);
    } else if (notification?.type === "connection-activity") {
      setProfileImg(
        notification?.Group?.icon ? notification?.Group?.icon : defaultGroupIcon
      );
      setMasterId(93);
      setNotificationMessage(<p>{notification?.content}</p>);
    } else if (notification?.type === "skill-endorsement") {
      setProfileImg(
        notification?.Group?.icon ? notification?.Group?.icon : defaultGroupIcon
      );
      setMasterId(114);
      setNotificationMessage(<p>{notification?.content}</p>);
    } else if (notification?.type === "membership-reminder") {
      setProfileImg(
        notification?.Group?.icon ? notification?.Group?.icon : defaultGroupIcon
      );
      setMasterId(71);
      setNotificationMessage(<p>{notification?.content}</p>);
    } else if (notification?.type === "view-post") {
      setProfileImg(
        notification?.Requested_User?.profile_img
          ? notification?.Requested_User?.profile_img
          : defaultGroupIcon
      );
      setMasterId(74);
      setNotificationMessage(
        <>
          <p>
            <strong>
              {notification?.Requested_User?.first_name?.length > 15
                ? notification?.Requested_User?.first_name.slice(0, 15) + "..."
                : notification?.Requested_User?.first_name}
            </strong>{" "}
            <strong>
              {notification?.Requested_User?.last_name?.length > 15
                ? notification?.Requested_User?.last_name.slice(0, 15) + "..."
                : notification?.Requested_User?.last_name}
            </strong>{" "}
            {notification?.content}
          </p>{" "}
        </>
      );
    } else if (notification?.type === "np-alert") {
      setProfileImg(npLogo);
      setNotificationMessage(
        <>
          <p>{notification?.content}</p>{" "}
        </>
      );
    } else {
      setProfileImg(placeholderUser);
      setNotificationMessage(notification?.content);
    }
  }, [index, notification]);
  return (
    <div className="post-main-box  p-0 mt-0" key={index}>
      <div style={{ position: "relative" }}>
        <div
          className="main-post "
          style={{
            backgroundColor: notification?.read ? "white" : "lightgrey",
            marginBottom: "3px",
            padding: "20px",
          }}
        >
          <div
            className="main-Post-div "
            onClick={() => handleItemClick(notification)}
          >
            <div className="notification-main-div">
              <img
                src={profileImg}
                alt="noti-prof-img"
                style={
                  notification?.type === "np-alert"
                    ? {
                        backgroundColor: "#EEF0F2",
                        padding: "5px",
                      }
                    : { backgroundColor: "#EEF0F2" }
                }
              />
              <div className="notify-detail">
                {notificationMessage}
                {notification?.type === "connection-request" && (
                  <div className="d-flex mt-2">
                    <Link to="/invitations">
                      <span
                        style={{ color: "#0432A3", marginRight: "20px" }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        Ignore
                      </span>
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        style={{
                          color: "rgb(4, 50, 163)",
                          border: "1px solid rgb(4, 50, 163)",
                          padding: "0px 10px",
                          borderRadius: "25px",
                          backgroundColor: "white",
                        }}
                      >
                        Accept
                      </span>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>
            <div
              ref={dropRef}
              className="dots clickDots "
              style={{ background: "none" }}
              onClick={() => {
                if (dropDown === index) {
                  setDropDown(-1);
                } else {
                  setDropDown(index);
                }
              }}
            >
              <span className="clickDots">&#x2022;</span>
              <span className="clickDots">&#x2022;</span>
              <span className="clickDots">&#x2022;</span>
            </div>
            <p
              className={
                "mt-3" +
                (getNotiTime(notification?.createdAt).length < 3 ? " ms-2" : "")
              }
            >
              {getNotiTime(notification?.createdAt)}
            </p>
          </div>
        </div>
        <div className="post">
          <div className="drop">
            {dropDown == index && (
              <div className=" notificationListMenuDropdown">
                <ul id="dropdownBox" ref={dropRef}>
                  <li
                    className="notification-dropdown-span"
                    onClick={() => handleNotificationDelete(notification?.id)}
                  >
                    <span>Delete</span>
                  </li>

                  {notification?.read ? (
                    <li
                      className="notification-dropdown-span"
                      onClick={() =>
                        handleNotificationUpdate(
                          notification?.id,
                          "read",
                          !notification?.read
                        )
                      }
                    >
                      <span>Mark As Unread</span>
                    </li>
                  ) : (
                    <li
                      className="notification-dropdown-span"
                      onClick={() =>
                        handleNotificationUpdate(
                          notification?.id,
                          "read",
                          !notification?.read
                        )
                      }
                    >
                      <span>Mark As read</span>
                    </li>
                  )}
                  {notification?.type !== "connection-request" &&
                    notification?.type !== "newsletter-subscribe" &&
                    notification?.type !== "page-admin" &&
                    notification?.type !== "page-transfer" &&
                    notification?.type !== "page-inivitation" &&
                    notification?.type !== "page-reached-insight" &&
                    notification?.type !== "page-followed-insight" &&
                    notification?.type !== "page-engaged-insight" &&
                    notification?.type !== "group-join" &&
                    notification?.type !== "smart-interview" && (
                      <li
                        className="notification-dropdown-span"
                        onClick={() => {
                          handleTrunOff(notification?.id);
                        }}
                      >
                        <span>Turn Off Notifications</span>
                      </li>
                    )}
                  {notification?.pinned ? (
                    <li
                      className="notification-dropdown-span"
                      onClick={() =>
                        handleNotificationUpdate(
                          notification?.id,
                          "pinned",
                          !notification?.pinned
                        )
                      }
                    >
                      <span>Unpin Notification</span>
                    </li>
                  ) : (
                    <li
                      className="notification-dropdown-span"
                      onClick={() =>
                        handleNotificationUpdate(
                          notification?.id,
                          "pinned",
                          !notification?.pinned
                        )
                      }
                    >
                      <span>Pin Notification</span>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowNotification;
