import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { call } from "../../api/apiCall";
import Help from "./Help";
import HelpWebView from "./HelpWebView";
import { getFAQData, postSupportData } from "../../store/help/helpAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { initLoad, stopLoad } from "../../store/loader/loaderActions";
import axios from "axios";
import { getLandingPageData } from "../../store/landing/landingAction";
import { directoryApi } from "../../api/directoryApi";

export default function HelpContainer(props, args) {
  const { user } = useSelector((state) => state.auth);
  const [modal, setModal] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [subcollapse, subCollapse] = useState(false);
  const toggle = () => setCollapse(!collapse);
  const subtoggle = () => subCollapse(!subcollapse);
  const modals = () => {
    setModal(!modal);
  };
  const [data, setData] = useState([]);
  const [helpData, setHelpData] = useState();
  const [customSub, setCustomSub] = useState(false);
  const initialState = {
    full_name:
      `${user?.data?.first_name || ""} ${user?.data?.last_name || ""}` || "",
    email: user?.data?.email || "",
    support_type: "",
    subject: "",
    custom_subject: "",
    message: "",
    page_link: "",
    device_type: "",
    media: "",
  };
  const [formData, setFormData] = useState(initialState);
  const faqList = useSelector((state) => state.help.faq);
  const [questions, setQuestions] = useState([]);
  const [type, setType] = useState();
  const [mode, setMode] = useState("");
  const dispatch = useDispatch();
  const [headingData, setHeadingData] = useState([]);
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    let params = new URL(document.location).searchParams;
    let param = params.get("mode");
    setMode(param);
    setFormData({
      full_name:
        `${user?.data?.first_name || ""} ${user?.data?.last_name || ""}` || "",
      email: user?.data?.email || "",
      support_type: "",
      subject: "",
      custom_subject: "",
      message: "",
      page_link: "",
      device_type: "",
      media: "",
    });
  }, []);

  useEffect(() => {
    faqs();
    dispatch(getFAQData(1, "is_popular"));
    dispatch(getLandingPageData({ title: "help_center" })).then((res) => {
      setHelpData(res);
    });
    headingDatas();

    call(
      "get",
      `api/v1/public/member`,
      { owner: true, limit: 1 },
      null,
      null
    ).then((response) => {
      if (response?.data?.data?.length) {
        const owner_id = response.data?.data[0]?.id;
        console.log(owner_id);
        call(
          "get",
          `api/v1/public/article`,
          { userId: owner_id, limit: 3 },
          null,
          null
        ).then((response) => {
          if (response?.data) {
            setArticles(response.data?.data?.length ? response.data.data : []);
          }
        });
      }
    });
  }, []);

  useEffect(() => {
    if (faqList) {
      setQuestions(faqList);
    } else {
      setQuestions([]);
    }
  }, [faqList]);

  function faqs() {
    new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call("get", "api/v1/faq", null, null, null);

          if (res.data.success === true) {
            resolve(res.data);
            setData(res.data.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
            setData([]);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  function headingDatas() {
    new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            "api/v1/faq",
            { heading: true },
            null,
            null
          );
          if (res.data.success === true) {
            resolve(res.data);
            setHeadingData(res.data.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
            setHeadingData([]);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handlefileChange = async (e) => {
    var fileExtension = e.target.value.split(".").pop();
    var validFile = true;
    let videoValid = /^(mp4|MP4)$/;
    let ImageValid = /^(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/;
    let TextValid = /^(pdf|ppt|pptx|txt|^text\/plain|doc|docx|xls|xlsx)$/;
    setType(fileExtension);
    if (
      ImageValid.test(fileExtension) == true ||
      TextValid.test(fileExtension) == true
    ) {
      try {
        const UPLOAD_URL = `${process.env.REACT_APP_API_URL}api/v1/upload`;
        const formUploadData = new FormData();
        const headers = {
          "Accept-Language": "en",
          "content-type": "multipart/form-data",
        };
        formUploadData.append("image", e.target.files[0]);
        dispatch(initLoad());
        await axios
          .post(UPLOAD_URL, formUploadData, { headers })
          .then((res) => {
            dispatch(stopLoad());
            let { urlsArray } = res.data;
            setFormData({ ...formData, media: urlsArray[0] });
          });
      } catch (err) {
        if (err.response.data.message === "File too large") {
          toast.error("File is too large, please upload another file");
          //alert("File is too large, please upload another file");
          dispatch(stopLoad());
          return;
        }
        console.log(err);
      }
    } else {
      alert("Please upload only pdf/txt/text/images");
    }
  };

  const handleClose = async (img) => {
    let token = window.localStorage.getItem("accessToken");
    setFormData({ ...formData, media: "" });
    const headers = {
      "Accept-Language": "en",
      authorization: token ? `Bearer ${token}` : "",
    };
    await axios.delete(
      `${process.env.REACT_APP_API_URL}api/v1/delete-document?url=${img}`,
      { headers }
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("ram");
    dispatch(postSupportData(formData)).then((response) => {
      toast.success("Submitted successfully!", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      setFormData(initialState);
      modals();
    });
  };

  if (mode === "mobile") {
    return (
      <>
        <HelpWebView
          questions={questions}
          collapse={collapse}
          data={data}
          subCollapse={subcollapse}
          subtoggle={subtoggle}
          toggle={toggle}
          modal={modal}
          modals={modals}
          handleSubmit={handleSubmit}
          formData={formData}
          handleChange={handleChange}
          handlefileChange={handlefileChange}
          type={type}
          handleClose={handleClose}
          // formdataError={formdataError}
        />
        <ToastContainer />
      </>
    );
  }
  return (
    <>
      <Help
        questions={questions}
        collapse={collapse}
        data={data}
        subCollapse={subcollapse}
        subtoggle={subtoggle}
        toggle={toggle}
        modal={modal}
        modals={modals}
        handleSubmit={handleSubmit}
        formData={formData}
        handleChange={handleChange}
        handlefileChange={handlefileChange}
        type={type}
        handleClose={handleClose}
        helpData={helpData}
        headingData={headingData}
        customSub={customSub}
        setCustomSub={setCustomSub}
        articles={articles}
        // handleValidation={handleValidation}
        // formdataError={formdataError}
      />
      <ToastContainer />
    </>
  );
}
