import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getLandingPageData } from "../../../../store/landing/landingAction";

export default function Footer() {
  const { firstFooter } = useSelector((state) => state.landingPage);
  console.log(firstFooter);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLandingPageData({ title: "footer-1" }));
  }, []);

  return (
    <div>
      <footer>
        <div className="container">
          <div className="line mt-3" style={{ padding: "10px" }}></div>
          <div className="py-3" style={{ marginTop: "-18px" }}>
            <div className="text-center">
              <ul style={{ columnGap: 0 }}>
                <li className=" me-0">
                  {firstFooter?.media && (
                    <img
                      src={firstFooter?.media}
                      alt="logo"
                      style={{ maxHeight: "50px" }}
                    />
                  )}
                  <p>
                    {firstFooter?.title?.replace(
                      "YYYY",
                      new Date().getFullYear()
                    )}
                  </p>{" "}
                </li>

                {firstFooter?.body?.map((item, idx) => (
                  <>
                    <li className="mx-0">
                      <span className="disc-size mx-4">&#x2022;</span>

                      <Link to={`/${item.router_link}`}>
                        <span>{item?.title}</span>
                      </Link>
                    </li>
                  </>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
