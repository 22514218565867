import React, { useState } from "react";
import Header from "../../core/components/layout/Header/Header";
import LoginFooter from "../../core/components/layout/footer/LoginFooter";
import lamp from "../../assets/img/lamp-on.svg";
import profile from "../../assets/img/user.svg";
import ticket from "../../assets/img/ticket-discount.svg";
import security from "../../assets/img/security.svg";
import brifcase from "../../assets/img/briefcase.svg";
import user from "../../assets/img/profile-add.svg";
import placeholderUser from "../../assets/img/placeholder_user.jpg";
import nopreview from "../../assets/img/no-preview.png";
import { Collapse, CardBody, Card } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import "./Help.scss";
import ContactUsModal from "./components/ContactUsModal";
import HelpCenterSearch from "./components/HelpCenterSearch";
import Prizing from "./components/Prizing";
import RelatedExplores from "../explore/RelatedExplores/RelatedExplores";
import moment from "moment";
import { createMarkup } from "../../core/components/Utility/Utility";
import { useSelector } from "react-redux";

export default function Help(props, args) {
  const {
    questions,
    collapse,
    data,
    subcollapse,
    toggle,
    modals,
    modal,
    handleSubmit,
    formData,
    handleChange,
    handlefileChange,
    type,
    handleClose,
    prizeData,
    prizeUnderData,
    helpData,
    headingData,
    customSub,
    setCustomSub,
    articles,
  } = props;

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [subcollapsed, setSubCollapsed] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  // const subtoggled = () => setSubCollapsed(!subcollapsed);

  console.log(user);

  return (
    <>
      <div>
        <Header />
        <div className="help">
          <HelpCenterSearch helpData={helpData} />
          <div className="context">
            <section className="padding">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="wrapper">
                      <div className="heading mb-3">
                        <h2>NobelPage Frequently Asked Questions</h2>
                      </div>
                      <div className="heading mb-3">
                        <h3>Popular Questions</h3>
                      </div>
                      <div className="list">
                        {/* <h5>Popular Questions</h5> */}
                        <ul>
                          {questions.map((item, id) => {
                            return (
                              <li key={id}>
                                {/* <Link to={``}>Ram</Link> */}
                                <Link to={`/help/question/${item.id}`}>
                                  <h6
                                    style={{
                                      fontSize: "inherit",
                                      lineHeight: "inherit",
                                    }}
                                    className="card-control-text-oneline"
                                    title={item.question}
                                  >
                                    {item.question}
                                  </h6>
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                    <div className="wrapper">
                      <div className="heading mt-30">
                        <h5>Recommended Topics</h5>
                      </div>
                      <div className="main-box row">
                        <div className="box col-lg-4">
                          <div
                            className="text-center"
                            style={{ cursor: "pointer" }}
                          >
                            <Link
                              to={`/help/question/topic`}
                              state={{ tag: "basic" }}
                            >
                              <div className="icon">
                                <img src={lamp} alt="" />
                              </div>
                              <div className="content">
                                <p>Basics</p>
                              </div>
                            </Link>
                          </div>
                        </div>
                        <div className="box col-lg-4">
                          <div
                            className="text-center"
                            style={{ cursor: "pointer" }}
                          >
                            <Link
                              to={`/help/question/topic`}
                              state={{ tag: "profile" }}
                            >
                              <div className="icon">
                                <img src={profile} alt="" />
                              </div>
                              <div className="content">
                                <p>Your Profile</p>
                              </div>
                            </Link>
                          </div>
                        </div>
                        <div className="box col-lg-4">
                          <div
                            className="text-center"
                            style={{ cursor: "pointer" }}
                          >
                            <Link
                              to={`/help/question/topic`}
                              state={{ tag: "subscription" }}
                            >
                              <div className="icon">
                                <img src={ticket} alt="" />
                              </div>
                              <div className="content">
                                <p>Subscription Billing</p>
                              </div>
                            </Link>
                          </div>
                        </div>
                        <div className="box col-lg-4">
                          <div
                            className="text-center"
                            style={{ cursor: "pointer" }}
                          >
                            <Link
                              to={`/help/question/topic`}
                              state={{ tag: "privacy" }}
                            >
                              <div className="icon">
                                <img src={security} alt="" />
                              </div>
                              <div className="content">
                                <p>Data and Privacy</p>
                              </div>
                            </Link>
                          </div>
                        </div>
                        <div className="box col-lg-4">
                          <div
                            className="text-center"
                            style={{ cursor: "pointer" }}
                          >
                            <Link
                              to={`/help/question/topic`}
                              state={{ tag: "job_search" }}
                            >
                              <div className="icon">
                                <img src={brifcase} alt="" />
                              </div>
                              <div className="content">
                                <p>Search and Apply for Jobs</p>
                              </div>
                            </Link>
                          </div>
                        </div>
                        <div className="box col-lg-4">
                          <div
                            className="text-center"
                            style={{ cursor: "pointer" }}
                          >
                            <Link
                              to={`/help/question/topic`}
                              state={{ tag: "connection" }}
                            >
                              <div className="icon">
                                <img src={user} alt="" />
                              </div>
                              <div className="content">
                                <p>Connections</p>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordian">
                      <Prizing headingData={headingData} />
                    </div>
                  </div>
                </div>
                <div className="help-article">
                  <h5 className="mt-5 mb-3 text-dark">Suggested Articles</h5>
                  <div className="row">
                    {articles?.map((data, index) => (
                      <div className="col-lg-4 col-md-4 mt-2" key={data.id}>
                        <div className="card overflow-hidden card-height-article">
                          <div className="">
                            <div
                              className="img card-image-square"
                              onClick={() =>
                                navigate(
                                  user?.data
                                    ? `/articledetail/${data?.id}`
                                    : `/directory/articledetail?id=${data?.id}`
                                )
                              }
                              style={{ cursor: "pointer" }}
                            >
                              {data?.media ? (
                                <img
                                  src={data?.media ? data?.media : nopreview}
                                  className="card-image-square"
                                  alt="newsletter-card"
                                />
                              ) : (
                                <img alt="" />
                              )}
                            </div>
                            {/* </Link> */}
                          </div>
                          <div className="card-header">
                            <div className="d-flex align-items-center gap-3">
                              <div
                                className="profile-img"
                                onClick={() =>
                                  navigate(`/profile/${data?.user?.user_id}`)
                                }
                              >
                                {/* <img
                           src={
                             data?.user?.profile_img
                               ? data?.user?.profile_img
                               : placeholderUser
                           }
                           className="image-fit"
                           alt=""
                           style={{ width: "36px" }}
                         /> */}
                                <img
                                  src={
                                    data?.user?.profile_img
                                      ? data?.user?.profile_img
                                      : placeholderUser
                                  }
                                  className="image-fit"
                                  alt=""
                                  style={{ width: "36px" }}
                                />
                              </div>
                              <span
                                className="name card-control-text-oneline"
                                onClick={() =>
                                  navigate(`/profile/${data?.user?.user_id}`)
                                }
                              >
                                {data?.user?.first_name} {data?.user?.last_name}
                              </span>
                            </div>
                            <div className="date">
                              <div className="d-flex">
                                <span className="calender-icon"></span>
                                <div className="days ml-5">
                                  {" "}
                                  <span>
                                    {moment(data?.createdAt).format(
                                      "ddd, MMM DD, YYYY"
                                    )}{" "}
                                    at{" "}
                                    {moment(data?.createdAt).format(
                                      "hh:mm A z"
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <h3 className="card-text-control">{data.title}</h3>
                          </div>
                          <div className="card-body">
                            <p className="card-text-control">
                              {data?.content && (
                                <div
                                  className="card-control-text-twoline"
                                  dangerouslySetInnerHTML={createMarkup(
                                    data?.content
                                  )}
                                ></div>
                              )}
                            </p>
                            <div
                              className="read"
                              onClick={() => {
                                navigate(
                                  user?.data
                                    ? `/articledetail/${data?.id}`
                                    : `/directory/articledetail?id=${data?.id}`
                                );
                              }}
                            >
                              Read article
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="bottom my-2">
                  <ul>
                    <li onClick={modals}>Contact us</li>
                    {/* <li>
                      <Link to="/help" onClick={scrollToTop}>FAQ</Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="line mt-3"></div>
        <LoginFooter />
      </div>
      <ContactUsModal
        modal={modal}
        modals={modals}
        args={args}
        handleSubmit={handleSubmit}
        formData={formData}
        handleChange={handleChange}
        handlefileChange={handlefileChange}
        type={type}
        handleClose={handleClose}
        customSub={customSub}
        setCustomSub={setCustomSub}
      />
    </>
  );
}
