import React, { useCallback, useEffect, useState } from "react";
import Navbar from "../../../pages/navbar/navbar";
import "./Recruiter.scss";
import { ToastContainer, toast } from "react-toastify";
import placeholderUser from "../../../assets/img/placeholder_user.jpg";
import downArrowIcon from "../../../assets/img/down-arrow2.svg";
import Autocomplete from "react-autocomplete";
import buld from "../../../assets/img/circle-building.svg";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash/debounce"; // Import the lodash debounce function
import { getLocationsData } from "../../../store/connections/connectionsActions";
import {
  getAllCompanyList,
  getSkills,
} from "../../../store/profile/profileActions";
import ShortlistModal from "../PostJob/components/ShortlistModal";
import AppLoader from "../../../core/components/Loaders/appLoader/appLoader";
import { useNavigate } from "react-router-dom";
import { getJobs, getSearchHistory } from "../../../store/jobs/jobAction";
import { getCandidateList } from "../../../store/recuriterSearch/recuriterAction";
import InfiniteScroll from "react-infinite-scroll-component";
import Ad from "../../../core/components/layout/Sidebar/Ad";
import SidebarFooter from "../../setting/SidebarFooter";
import Chat from "../../../pages/chat/chat";
import InfoIcon from "../../../assets/img/InfoIcon.svg";
import exp_tb from "../../../assets/img/exp_badge.svg";
import edu_tb from "../../../assets/img/edu_badge.svg";
import cri_tb from "../../../assets/img/criminal_badge.svg";
import { call } from "../../../api/apiCall";
const dropdownOptions = {
  experience: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
  verificationType: [
    { name: "Education Check", value: "education" },
    { name: "Experience Check", value: "experience" },
    { name: "Criminality Check", value: "criminal" },
  ],
};

export default function Recruiter(props) {
  const { activeCandidate, setActiveCandidate } = props;

  const [memberInput, setMemberInput] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [currentCount, setCurrentCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isMoreData, setIsMoreData] = useState();
  const [locationOpen, setLocationOpen] = useState(false);
  const [searchFieldValue, setSearchFieldValue] = useState(null);
  const [locationsAPIData, setLocationsAPIData] = useState([]);
  const [pageDetails, setPageDetails] = useState({
    location: searchFieldValue,
  });
  const [countryList, setCountryList] = useState("");
  const [shortlistModal, setShortlistModal] = useState(false);
  const [shortListUser, setShortListUser] = useState();
  const [experienceValue, setExperienceValue] = useState(null);
  const [nationalValue, setNationalValue] = useState(null);
  const [verificationValue, setVerificationValue] = useState(null);
  const [searchCompany, setSearchCompany] = useState("");
  const [skillValue, setSkillValue] = useState("");
  const [skillList, setSkillList] = useState([]);
  const [targetSkill, setTargetSkill] = useState("");
  const dispatch = useDispatch();
  const { isPageLoading } = useSelector((state) => state.loader);
  const { user } = useSelector((state) => state.auth);
  const { getMyJobs } = useSelector((state) => state.jobs);
  const { searchHistory } = useSelector((state) => state.jobs);
  const navigate = useNavigate();

  const handleCompanySearch = useCallback(
    debounce((val) => {
      setMemberInput(val);
    }, 500),
    []
  );

  const handleExperienceChange = debounce((value) => {
    setExperienceValue(value);
    setCurrentCount(0);
  }, 300);

  const handleSkillsSearch = debounce((value) => {
    setSkillValue(value);
    setCurrentCount(0);
  }, 300);

  const handleVerificationTypeChange = (val) => {
    setVerificationValue(val);
  };

  useEffect(() => {
    if (memberInput) {
      dispatch(getAllCompanyList({ search: memberInput }))
        .then((res) => {
          if (res?.success === true) {
            setCompanyList(res?.data);
          } else setCompanyList([]);
        })
        .catch(() => {
          setCompanyList([]);
        });
    }
  }, [memberInput]);

  const getLocations = ({ value }) => {
    if (value)
      dispatch(getLocationsData(value))
        .then((response) => {
          if (response.data) {
            setLocationsAPIData(response.data);
          } else setLocationsAPIData([]);
        })
        .catch((err) => {
          setLocationsAPIData([]);
        });
    else setLocationsAPIData([]);
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await call("get", "api/v1/country", null, null);
        setCountryList(res.data);
      } catch (err) {
        console.log(err);
      }
    })();
    dispatch(getJobs({ filter: "my" }, 0, "my"));
    dispatch(getSearchHistory({ limit: 6, skip: 0, type: "job" }));
  }, []);

  const loadMoreData = () => {
    setTimeout(() => {
      setCurrentCount(currentCount + 10);
    }, 1000);
  };

  useEffect(() => {
    if (candidateList?.length < totalCount) setIsMoreData(true);
    else setIsMoreData(false);
  }, [currentCount, totalCount]);

  useEffect(() => {
    const query = {
      limit: 10,
      skip: 0,
      location: searchFieldValue || undefined,
      experience: experienceValue || undefined,
      national: nationalValue || undefined,
      verification_type: verificationValue || undefined,
      company: memberInput || undefined,
      skills: targetSkill || undefined,
    };

    dispatch(getCandidateList(query)).then((res) =>
      setTotalCount(res?.totalCount)
    );
  }, [
    searchFieldValue,
    experienceValue,
    nationalValue,
    verificationValue,
    memberInput,
    targetSkill,
  ]);

  useEffect(() => {
    if (currentCount > 0) {
      const query = {
        limit: 10,
        skip: currentCount,
        location: searchFieldValue || undefined,
        experience: experienceValue || undefined,
        national: nationalValue || undefined,
        verification_type: verificationValue || undefined,
        company: memberInput || undefined,
        skills: targetSkill || undefined,
      };

      dispatch(getCandidateList(query)).then((res) =>
        setTotalCount(res?.totalCount)
      );
    }
  }, [currentCount]);

  const handleShortList = (id) => {
    if (getMyJobs?.length > 0) {
      setShortlistModal(true);
      setShortListUser(id);
    } else {
      alert("Create your jobs before shortlisting the candidate");
      navigate("/jobs", {
        state: {
          isOpenCreatePost: true,
        },
      });
    }
  };

  const candidateList = useSelector((state) => state.recuriter.candidateList);

  const handleViewDetails = (index) => {
    if (activeCandidate === index) {
      setActiveCandidate();
    } else {
      setActiveCandidate(index);
    }
  };

  // const handleViewDetails = (index) => {
  //   setActiveCandidate(index === activeCandidate ? null : index);
  // };

  useEffect(() => {
    dispatch(getSkills({ search: skillValue }))
      .then((res) => {
        setSkillList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [skillValue]);

  const [locationInput, setLocationInput] = useState("");

  return (
    <div className="recruiter">
      <Navbar isJobs={false} />
      <div className="container mt-4">
        {isPageLoading && <AppLoader />}
        <div className="row">
          <div className="col-md-9 main-content">
            <div className="bg-white p-2 recruiter-bar">
              <div className="filter-option">
                <ul className="row p-0">
                  <li className="autocomplete-wrapper pe-0">
                    <div className="search">
                      <Autocomplete
                        open={locationOpen}
                        inputProps={{
                          placeholder: "Location",
                          style: {
                            borderBottom: "1px solid #D1DFFF",
                            paddingBottom: "5px",
                          },
                        }}
                        value={locationInput}
                        items={locationsAPIData}
                        getItemValue={(item) => item.name}
                        shouldItemRender={() => locationsAPIData}
                        renderMenu={(items, value) => (
                          <div className="dropdown">
                            {items.length === 0
                              ? `No matches for ${value}`
                              : items}
                          </div>
                        )}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "selected-item" : ""
                            }`}
                          >
                            {item.name}
                          </div>
                        )}
                        onChange={(e, val) => {
                          if (val.length === 0) {
                            if (locationOpen) setLocationOpen(false);
                            setSearchFieldValue("");
                          } else {
                            if (!locationOpen) setLocationOpen(true);
                          }
                          getLocations(e.target);
                          setLocationInput(e.target.value);
                        }}
                        onSelect={(value, obj) => {
                          setSearchFieldValue(value);
                          setLocationInput(value);
                          setPageDetails({
                            ...pageDetails,
                            location: obj.name,
                          });
                          setLocationOpen(false);
                        }}
                        wrapperStyle={{}}
                      />
                    </div>
                  </li>
                  <li className="autocomplete-wrapper pe-0">
                    <Autocomplete
                      value={skillValue}
                      items={skillList}
                      getItemValue={(item) => item.skills}
                      renderMenu={(items, value) =>
                        items.length === 0 ? (
                          <></>
                        ) : (
                          <div className="dropdown" style={{ width: "22%" }}>
                            {items}{" "}
                          </div>
                        )
                      }
                      renderItem={(item, isHighlighted) => (
                        <div
                          className={`item ${
                            isHighlighted ? "selected-item" : ""
                          }`}
                          style={{ display: "flex" }}
                        >
                          <div>
                            <div className="name-heading-drop ">
                              {item.skills}
                            </div>
                          </div>
                        </div>
                      )}
                      onChange={(event, val) => {
                        setSkillValue(val);
                        if (!val) setTargetSkill("");
                      }}
                      onSelect={(val, obj) => {
                        setSkillValue(val);
                        setTargetSkill(val);
                      }}
                      wrapperStyle={{}}
                      renderInput={(params) => {
                        return (
                          <div class="fake-input">
                            <input
                              className="custom-placeholder"
                              {...params}
                              type="text"
                              placeholder={"Skill"}
                            />
                          </div>
                        );
                      }}
                    />
                  </li>
                  <li className=" px-0">
                    <select
                      className="form-select"
                      style={{
                        width: "130px",
                        fontWeight: "500",
                        fontSize: "14px",
                      }}
                      onChange={(e) => handleExperienceChange(e.target.value)}
                    >
                      <option value="0">Experience</option>
                      {dropdownOptions?.experience?.map((option, index) => (
                        <option key={index} value={option}>
                          {option === 0 ? "Fresher" : option}{" "}
                          {option === 0 ? "" : option === 1 ? "Year" : "Years"}
                        </option>
                      ))}
                    </select>
                  </li>
                  <li className=" px-0">
                    <select
                      className="form-select"
                      style={{
                        width: "170px",
                        fontWeight: "500",
                        fontSize: "14px",
                        display: "block",
                      }}
                      onChange={(e) =>
                        handleVerificationTypeChange(e.target.value)
                      }
                    >
                      <option default value="">
                        Verification Type
                      </option>
                      {dropdownOptions.verificationType.map((option, index) => (
                        <option key={index} value={option?.value}>
                          {option?.name}
                        </option>
                      ))}
                    </select>
                  </li>
                  <li className="autocomplete-wrapper current-company ">
                    <Autocomplete
                      value={searchCompany}
                      items={companyList}
                      getItemValue={(item) => item.name}
                      renderMenu={(items, value) =>
                        items.length === 0 ? (
                          <></>
                        ) : (
                          <div className="dropdown" style={{ width: "22%" }}>
                            {items}{" "}
                          </div>
                        )
                      }
                      renderItem={(item, isHighlighted) => (
                        <div
                          className={`item ${
                            isHighlighted ? "selected-item" : ""
                          }`}
                          style={{ display: "flex" }}
                        >
                          <img
                            src={item.icon ? item.icon : buld}
                            alt="dropdown_pic"
                            className="image-fit mr-10"
                            style={{ height: "36px", width: "36px" }}
                          />
                          <div>
                            <div className="name-heading-drop ">
                              {item.name}
                            </div>
                          </div>
                        </div>
                      )}
                      onChange={(event, val) => {
                        setSearchCompany(val || "");
                        handleCompanySearch(val || "");
                      }}
                      onSelect={(val, obj) => {
                        setSearchCompany(val || "");
                        handleCompanySearch(val || "");
                      }}
                      wrapperStyle={{}}
                      renderInput={(params) => {
                        return (
                          <div class="fake-input">
                            <input
                              className="custom-placeholder"
                              {...params}
                              type="text"
                              placeholder={"Current Company"}
                              style={{
                                padding: 0,
                                width: "100%",
                                marginTop: "-1px",
                              }}
                            />
                          </div>
                        );
                      }}
                    />
                  </li>
                  {user?.data?.membership?.id == 4 && (
                    <li className="ps-0">
                      <select
                        className="form-select"
                        style={{
                          fontWeight: "500",
                          fontSize: "14px",
                          width: "100%",
                          maxHeight: "35px",
                          display: "block",
                        }}
                        onChange={(e) => setNationalValue(e?.target?.value)}
                      >
                        <option default value="">
                          <span>
                            Only National{" "}
                            <img src={InfoIcon} width={15} height={15} />
                          </span>
                        </option>
                        {countryList?.data?.map((item, index) => {
                          return (
                            <option key={index} value={item?.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </li>
                  )}

                  {/* <li className="">Clear Filter</li> */}
                </ul>
              </div>
            </div>
            <InfiniteScroll
              dataLength={candidateList?.length ? candidateList?.length : 0}
              next={loadMoreData}
              hasMore={isMoreData}
              style={{ overflow: "visible" }}
            >
              <div className="shortlist bg-white mt-30 mb-20">
                {candidateList.length > 0 ? (
                  candidateList?.map((data, index) => {
                    const empCheck = data?.experiences?.some(
                      (exp) => exp?.screening_status == "Verified"
                    );
                    const eduCheck = data?.educations?.some(
                      (edu) => edu?.screening_status == "Verified"
                    );
                    const criCheck = data?.screening_status == "Verified";
                    return (
                      <div key={index} className="mb-30">
                        <div className="d-flex justify-content-between">
                          <div className="user-post">
                            <div className="prof-img">
                              <img src={placeholderUser} alt="placeholder" />
                            </div>
                            <div>
                              <div className="d-flex align-items-center gap-2">
                                <h4 className="poster-name">Anonymous</h4>
                                {data?.is_email_verified && (
                                  // && (empCheck || eduCheck || criCheck)
                                  <div className="varify mx-0"></div>
                                )}
                                <div
                                  style={{
                                    display: "flex",
                                    columnGap: "10px",
                                  }}
                                >
                                  {empCheck && (
                                    <div class="tooltip-container">
                                      <img
                                        src={exp_tb}
                                        alt="Tooltip Icon"
                                        class="tooltip-trigger"
                                      />
                                      <div class="custom-tooltip">
                                        <p className="fw-bold">
                                          Employment Check
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                  {eduCheck && (
                                    <div class="tooltip-container">
                                      <img
                                        src={edu_tb}
                                        alt="Tooltip Icon"
                                        class="tooltip-trigger"
                                      />
                                      <div class="custom-tooltip">
                                        <p className="fw-bold">
                                          Education Check
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                  {criCheck && (
                                    <div class="tooltip-container">
                                      <img
                                        src={cri_tb}
                                        alt="Tooltip Icon"
                                        class="tooltip-trigger"
                                      />
                                      <div class="custom-tooltip">
                                        <p className="fw-bold">
                                          Criminal Check
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <p className="designation-name card-control-text-oneline">
                                {data?.profile?.designation}{" "}
                                {data?.profile?.profile_headline}
                              </p>
                              <div
                                className="d-flex gap-1 mt-10"
                                onClick={() => handleViewDetails(index)}
                              >
                                <p className="designation-name blue-color curserPointer">
                                  View Details
                                </p>
                                <img
                                  src={downArrowIcon}
                                  alt="arrow-icon"
                                  className="curserPointer"
                                  style={
                                    activeCandidate === index
                                      ? { transform: "rotate(180deg)" }
                                      : {}
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div
                            className="btn btn-blue"
                            onClick={() => {
                              handleShortList(data?.id);
                            }}
                            style={{
                              height: "40px",
                              width: "123px",
                              borderRadius: "5px",
                              padding: "8.5px 30px",
                            }}
                          >
                            <span style={{ fontSize: "15px" }}>Shortlist</span>
                          </div>
                        </div>
                        {/* {activeCandidate === index && (
                          <>
                            <div className="line mt-20 mb-20"> </div>
                            {!data?.totalExperience &&
                            data?.experiences?.length == 0 &&
                            data?.educations?.length == 0 &&
                            data?.honors?.length == 0 &&
                            data?.courses?.length == 0 &&
                            data?.license_certifications?.length == 0 &&
                            data?.languages?.length == 0 &&
                            data?.skills?.length == 0 ? (
                              <div
                                className="row"
                                style={{ paddingLeft: "20px" }}
                              >
                                No details available
                              </div>
                            ) : (
                              <div className="row">
                                {console.log(data, "gdgdfgd")}
                                {data?.totalExperience && (
                                  <>
                                    <div className="col-md-3 mt-2 mb-2">
                                      Total Experience
                                    </div>
                                    <div className="col-md-9 mt-2 mb-2">
                                      <span
                                        className="job-details"
                                        style={{ fontWeight: "600" }}
                                      >
                                        {data?.totalExperience > 1
                                          ? data?.totalExperience + " Years"
                                          : data?.totalExperience + " Year"}
                                      </span>
                                    </div>
                                  </>
                                )}
                                {data?.experiences?.length > 0 && (
                                  <>
                                    <div className="col-md-3 mb-2">
                                      Experience
                                    </div>
                                    <div className="col-md-9 mb-2  d-flex">
                                      {data?.experiences.map(
                                        (exp, expIndex) => (
                                          <div key={expIndex}>
                                            <span
                                              className="job-details"
                                              style={{ fontWeight: "600" }}
                                            >
                                              {exp.company_name}, {exp.title}
                                              {expIndex <
                                              data?.experiences.length - 1
                                                ? " , "
                                                : ""}
                                            </span>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </>
                                )}
                                {data?.educations?.length > 0 && (
                                  <>
                                    <div className="col-md-3 mb-2">
                                      Education
                                    </div>
                                    <div className="col-md-9 mb-2 d-flex">
                                      {data?.educations.map((exp, expIndex) => (
                                        <div key={expIndex}>
                                          <span
                                            className="job-details"
                                            style={{ fontWeight: "600" }}
                                          >
                                            {exp.degree}
                                            {expIndex <
                                            data?.educations.length - 1
                                              ? " , "
                                              : ""}
                                          </span>
                                        </div>
                                      ))}
                                    </div>
                                  </>
                                )}
                                {data?.honors?.length > 0 && (
                                  <>
                                    <div className="col-md-3 mb-2">
                                      Honour Awards
                                    </div>
                                    <div className="col-md-9 mb-2  d-flex">
                                      {data?.honors?.map(
                                        (honor, honorIndex) => (
                                          <div key={honorIndex}>
                                            <span
                                              className="job-details"
                                              style={{ fontWeight: "600" }}
                                            >
                                              {honor?.name}{" "}
                                              {honorIndex <
                                              data?.honors.length - 1
                                                ? " , "
                                                : ""}
                                            </span>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </>
                                )}
                                {data?.courses?.length > 0 && (
                                  <>
                                    <div className="col-md-3 mb-2  d-flex">
                                      Course
                                    </div>
                                    <div className="col-md-9 mb-2">
                                      <span
                                        className="job-details"
                                        style={{ fontWeight: "600" }}
                                      >
                                        {data?.courses.map(
                                          (course, courseIndex) => (
                                            <span
                                              key={courseIndex}
                                              style={{ fontWeight: "600" }}
                                            >
                                              {course.name}
                                              {courseIndex <
                                                data?.courses.length - 1 &&
                                                " , "}
                                            </span>
                                          )
                                        )}
                                      </span>
                                    </div>
                                  </>
                                )}
                                {data?.license_certifications?.length > 0 && (
                                  <>
                                    <div className="col-md-3 mb-2">
                                      Certificates
                                    </div>
                                    <div className="col-md-9 mb-2 d-flex">
                                      {data?.license_certifications?.map(
                                        (certi, certiIndex) => (
                                          <span
                                            className="job-details"
                                            style={{ fontWeight: "600" }}
                                          >
                                            {certi?.name}
                                            {certiIndex <
                                              data?.license_certifications
                                                .length -
                                                1 && " , "}
                                          </span>
                                        )
                                      )}
                                    </div>
                                  </>
                                )}

                                {data?.languages?.length > 0 && (
                                  <>
                                    <div className="col-md-3 mb-2">
                                      Language
                                    </div>
                                    <div className="col-md-9 mb-2">
                                      <span
                                        className="job-details"
                                        style={{ fontWeight: "600" }}
                                      >
                                        {data?.languages?.map(
                                          (language, languageIndex) => (
                                            <span
                                              key={languageIndex}
                                              style={{ fontWeight: "600" }}
                                            >
                                              {language.name}
                                              {languageIndex <
                                                data?.languages?.length - 1 &&
                                                ", "}
                                            </span>
                                          )
                                        )}
                                      </span>
                                    </div>
                                  </>
                                )}
                                {data?.skills?.length > 0 && (
                                  <>
                                    <div className="col-md-3">Skills</div>
                                    <div className="col-md-9 mb-2">
                                      <span
                                        className="job-details "
                                        style={{ fontWeight: "600" }}
                                      >
                                        {data?.skills.map(
                                          (skill, skillIndex) => (
                                            <span
                                              key={skillIndex}
                                              className="skill-span"
                                              style={{ fontWeight: "400" }}
                                            >
                                              {skill.skills}
                                            </span>
                                          )
                                        )}
                                      </span>
                                    </div>
                                  </>
                                )}
                              </div>
                            )}
                          </>
                        )} */}

                        {activeCandidate === index && (
                          <>
                            <div className="line mt-20 mb-20"> </div>
                            {!data?.totalExperience &&
                            data?.experiences?.length == 0 &&
                            data?.educations?.length == 0 &&
                            data?.honors?.length == 0 &&
                            data?.courses?.length == 0 &&
                            data?.license_certifications?.length == 0 &&
                            data?.languages?.length == 0 &&
                            data?.skills?.length == 0 ? (
                              <div
                                className="row"
                                style={{ paddingLeft: "20px" }}
                              >
                                No details available
                              </div>
                            ) : (
                              <div className="row">
                                {data?.totalExperience && (
                                  <>
                                    <div className="col-md-3 mt-2 mb-2">
                                      Total Experience
                                    </div>
                                    <div className="col-md-9 mt-2 mb-2">
                                      <span
                                        className="job-details"
                                        style={{ fontWeight: "600" }}
                                      >
                                        {data?.totalExperience < 1
                                          ? "Fresher"
                                          : data?.totalExperience > 1
                                          ? data?.totalExperience + " Years"
                                          : data?.totalExperience + " Year"}
                                      </span>
                                    </div>
                                  </>
                                )}
                                {data?.experiences?.length > 0 && (
                                  <>
                                    <div className="col-md-3 mb-2">
                                      Experience
                                    </div>
                                    <div className="col-md-9 mb-2 d-flex flex-wrap">
                                      {data?.experiences.map(
                                        (exp, expIndex) => (
                                          <div key={expIndex} className="me-2">
                                            <span
                                              className="job-details"
                                              style={{ fontWeight: "600" }}
                                            >
                                              {exp.company_name}, {exp.title}
                                              {expIndex <
                                              data?.experiences.length - 1
                                                ? " , "
                                                : ""}
                                            </span>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </>
                                )}
                                {data?.educations?.length > 0 && (
                                  <>
                                    <div className="col-md-3 mb-2">
                                      Education
                                    </div>
                                    <div className="col-md-9 mb-2 d-flex flex-wrap">
                                      {data?.educations.map((exp, expIndex) => (
                                        <div key={expIndex} className="me-2">
                                          <span
                                            className="job-details"
                                            style={{ fontWeight: "600" }}
                                          >
                                            {exp.degree}
                                            {expIndex <
                                            data?.educations.length - 1
                                              ? " , "
                                              : ""}
                                          </span>
                                        </div>
                                      ))}
                                    </div>
                                  </>
                                )}
                                {data?.honors?.length > 0 && (
                                  <>
                                    <div className="col-md-3 mb-2">
                                      Honour Awards
                                    </div>
                                    <div className="col-md-9 mb-2 d-flex flex-wrap">
                                      {data?.honors?.map(
                                        (honor, honorIndex) => (
                                          <div
                                            key={honorIndex}
                                            className="me-2"
                                          >
                                            <span
                                              className="job-details"
                                              style={{ fontWeight: "600" }}
                                            >
                                              {honor?.name}{" "}
                                              {honorIndex <
                                              data?.honors.length - 1
                                                ? " , "
                                                : ""}
                                            </span>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </>
                                )}
                                {data?.courses?.length > 0 && (
                                  <>
                                    <div className="col-md-3 mb-2">Course</div>
                                    <div className="col-md-9 mb-2 d-flex flex-wrap">
                                      {data?.courses.map(
                                        (course, courseIndex) => (
                                          <span
                                            key={courseIndex}
                                            className="me-2 job-details"
                                            style={{ fontWeight: "600" }}
                                          >
                                            {course.name}
                                            {courseIndex <
                                              data?.courses.length - 1 && " , "}
                                          </span>
                                        )
                                      )}
                                    </div>
                                  </>
                                )}
                                {data?.license_certifications?.length > 0 && (
                                  <>
                                    <div className="col-md-3 mb-2">
                                      Certificates
                                    </div>
                                    <div className="col-md-9 mb-2 d-flex flex-wrap">
                                      {data?.license_certifications?.map(
                                        (certi, certiIndex) => (
                                          <span
                                            key={certiIndex}
                                            className="me-2 job-details"
                                            style={{ fontWeight: "600" }}
                                          >
                                            {certi?.name}
                                            {certiIndex <
                                              data?.license_certifications
                                                .length -
                                                1 && " , "}
                                          </span>
                                        )
                                      )}
                                    </div>
                                  </>
                                )}
                                {data?.languages?.length > 0 && (
                                  <>
                                    <div className="col-md-3 mb-2">
                                      Language
                                    </div>
                                    <div className="col-md-9 mb-2">
                                      <span className="job-details">
                                        {data?.languages?.map(
                                          (language, languageIndex) => (
                                            <span
                                              key={languageIndex}
                                              // className="me-2 job-details"
                                              // style={{ fontWeight: "600" }}
                                            >
                                              {language.name}
                                              {languageIndex <
                                                data?.languages.length - 1 &&
                                                ", "}
                                            </span>
                                          )
                                        )}
                                      </span>
                                    </div>
                                  </>
                                )}

                                {data?.skills?.length > 0 && (
                                  <>
                                    <div className="col-md-3">Skills</div>
                                    <div className="col-md-9 mb-2">
                                      <div
                                        className="job-details d-flex flex-wrap"
                                        style={{ gap: "8px" }}
                                      >
                                        {data?.skills.map(
                                          (skill, skillIndex) => (
                                            <div
                                              key={skillIndex}
                                              className="skill-span"
                                              style={{ fontWeight: 400 }}
                                            >
                                              {skill.skills}
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <h4 className="text-center">No Data Found</h4>
                )}
              </div>
            </InfiniteScroll>
          </div>
          <div className="col-md-3 sidebar">
            <div className="bg-white px-4 py-3 top-sidebar">
              <p
                className="text-dark-blue"
                style={{ fontSize: "13px" }}
                onClick={() =>
                  // alert(
                  //   "This feature will be implemented in upcomming milestone"
                  // )
                  navigate("/shortlist/candidates", {
                    state: {
                      tab: "viewSmartInterview",
                    },
                  })
                }
              >
                Interview Answers
              </p>
              <p
                className="text-dark-blue mt-3"
                style={{ fontSize: "13px" }}
                onClick={() => navigate("/shortlist/candidates")}
              >
                Shortlisted Candidates
              </p>
              <p
                className="text-dark-blue mt-3 d-flex align-items-center"
                style={{ display: "flex", width: "101%", fontSize: "13px" }}
                onClick={() =>
                  window.open(
                    "https://nobelpageworkerpoolstage.dev.vinove.com/workers"
                  )
                }
              >
                Search Only Workers{" "}
                <span className="mt-1 mx-2">(WorkerPool)</span>
              </p>
            </div>

            {searchHistory?.length > 0 && (
              <div className="bg-white top-sidebar">
                <div className="px-4 py-3 mt-20">
                  <p className="text-dark-blue">Search History</p>
                </div>
                <div className="line"></div>
                <div style={{ paddingBottom: "20px" }}>
                  {searchHistory?.map((item, idx) => (
                    <div className="px-4 mt-20" key={idx}>
                      <p className="text-secondary fw-bold">{item?.search}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="my-3">
              <Ad />
            </div>
            <div className="sidebar-sticky">
              <SidebarFooter isNoBackground={true} />
            </div>
          </div>
        </div>
        <ToastContainer />
        <Chat />
      </div>
      {shortlistModal && (
        <ShortlistModal
          modal={shortlistModal}
          toggle={() => setShortlistModal(!shortlistModal)}
          shortListUser={shortListUser}
        />
      )}
    </div>
  );
}
